import { Component } from 'react';
import auth from "./auth";
import { Config } from '../base/portal_env_config/config';
import RouterUtil from '../base/utils/routerUtil';
import CookieUtil from './util/cookieUtil';
import { LeadParams } from '../model/basket/leadParams';


export default class HSPage extends Component {

    constructor(props) {
        super(props);
        let query = RouterUtil.getQuery() || {};
        let utm_source = query?.utm_source;
        let utm_medium = query?.utm_medium;
        let utm_campaign = query?.utm_campaign;
        if (utm_source || utm_medium || utm_campaign) {
            let utmParams: LeadParams = new LeadParams();
            utmParams.utm_source = utm_source;
            utmParams.utm_medium = utm_medium;
            utmParams.utm_campaign = utm_campaign;
            CookieUtil.setCookie('_hsUtmParams', utmParams, (new Date().getTime() + (10 * 24 * 3600 * 1000)));
        }
    }
    static async getInitialProps(ctx) {

        if (this.isProtectedRoute(ctx)) return {};

    
        return {
            // namespacesRequired: ['common', 'header','subscriptions']
        };
    }

    static isProtectedRoute(ctx) {
        console.log("isProtectedRoute: ", ctx);
        return (
            (typeof window === "undefined" && (process.env.staticDeployment || Config.portal.staticDeployment)) ||
            auth.routeGuard(ctx)
        );
    }
}
