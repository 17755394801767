import Cookies from "js-cookie";
import { CryptoUtil } from "./cryptoutil";
export default class CookieUtil {
  public static getCookie(key: string, req: any = {}) {
    if ((typeof window != 'undefined') && window) {
      if (req && req.headers && req.headers.cookie) {
        return CryptoUtil.decryptData(
          (req.headers.cookie + "")
            ?.split("; ")
            ?.filter((cookie) => cookie.includes(key + "="))?.[0]
            ?.replace(new RegExp(key + "=", "g"), "")
        );
      } else if (Cookies) {
        return CryptoUtil.decryptData(Cookies.get(key));
      }
    } else {
      return null;
    }
  }
  public static setCookie(
    key: string,
    value: any,
    expiry: number = 0,
    req: any = {}
  ) {
    if ((typeof window != 'undefined') && window) {
      if (req && req.headers && req.headers.cookie) {
        req.headers.cookie.set(key, value, {
          secure: true,
          sameSite: "Lax",
          path: "/",
        });
      } else {
        if (expiry > 0) {
          let date = new Date();
          date.setTime(expiry);
          Cookies.set(key, CryptoUtil.encryptData(value), {
            path: "/",
            expires: date,
            secure: true,
            sameSite: "Lax",
          });
        } else {
          Cookies.set(key, CryptoUtil.encryptData(value), {
            secure: true,
            sameSite: "Lax",
            path: "/",
          });
        }
      }
    }
  }
}
