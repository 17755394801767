import React, { Component } from 'react';
import Head from 'next/head';
import { Config } from "../../../base/portal_env_config/config";
import RouterUtil from '../../../base/utils/routerUtil';

export default class Meta extends Component {
    props: any;


    render() {

        let headers: any = {
            title: this.props.title || (Config.portal.name) || "Vijaya Diagnostic Centre",
            themeColor: this.getThemeColor(),
            description: this.props.description || `Vijaya Diagnostic Centre has honoured under “10 Most Trusted Brands of the Year 2019” by Business Sight Magazine, for valuable contribution as a leading brand  with excellent magnification, business practices, professional dedication, and successful journey in providing diagnostic services across India.`,
            ogType: "website",
            ogSiteName: Config.portal.name || "Vijaya Diagnostic Centre",
            ogImage: Config.portal.iconspath + 'favicon1.ico',
            ogURL: "",
            manifest: Config.portal.iconspath + 'manifest.json',
            icon: Config.portal.iconspath + 'favicon1.ico',
            canonical: this.props.canonical ? this.props.canonical : (typeof window !== "undefined" && ($ as any)(window)?.location?.href ? ($ as any)(window).location.href : ''),
            keywords: this.props?.keywords?.length > 0 ? this.props.keywords : "home sample collection, vijaya, covid-19, hyderabad, diagnostic, lab, tests, xray, ct scan, mri"
        };
        headers = {
            ...headers,
            ogTitle: headers.title || '',
            ogDescription: headers.description,
            twitterCard: 'summary',
            twitterTitle: headers.title,
            twitterImage: headers.icon,
            twitterDescription: headers.description,
            twitterSite: "",
            twitterCreator: "",
            appleTouchIcon: headers.icon,
            ...this.props
        }
        const schemaData = {
            "@context": "http://www.schema.org",
            "@type": "DiagnosticLab",
            "name": "Vijaya Diagnostic Centre Limited",
            "alternateName": "Vijaya Diagnostic Centre",
            "url": "https://www.vijayadiagnostic.com/",
            "logo": "https://www.vijayadiagnostic.com/vdc/icons/favicon.png",
            "description": "Vijaya Diagnostic Centre is a leading blood test laboratory in India, offering diagnostic services for 40 years. Book diagnostic tests online & Download Report!",
            "image": "https://www.vijayadiagnostic.com/vdc/icons/favicon.png",
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "Ground Floor, FPAI Building, Punjagutta Officers Colony, Near Topaz Building",
                "addressLocality": "Hyderabad",
                "addressRegion": "Telangana",
                "postalCode": "500082",
                "addressCountry": "India"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "040 2100 0000",
                "contactType": "customer service"
            },
            "sameAs": [
                "https://www.facebook.com/vijayadiagnostic",
                "https://twitter.com/vijayadiagnostic",
                "https://www.linkedin.com/company/vijayadiagnostic",
                "https://www.instagram.com/vdcconnect/",
                "https://www.youtube.com/vdcconnect/"
            ]
        };

        return (
            <Head>
                <title>{headers.title}</title>
                <meta name="description" content={headers.description} />
                <meta name="Content-type" content="text/html" />
                <meta name="keywords" content={headers.keywords}></meta>
                <meta name="apple-mobile-web-app-capable" content="yes"></meta>
                <meta name="apple-mobile-web-app-status-bar-style" content="default"></meta>
                <meta name="apple-mobile-web-app-title" content={(Config.portal.name) || "MyMedic"}></meta>
                <link rel="apple-touch-startup-image" href={Config.portal.iconspath + 'android-icon-512x512.png'}></link>
                <meta property="og:type" content={headers.ogType} />
                <meta name="og:title" property="og:title" content={headers.ogTitle} />
                <meta name="og:description" property="og:description" content={headers.ogDescription} />
                <meta property="og:site_name" content={headers.ogSiteName} />
                <meta property="og:url" content={headers.ogURL} />
                <meta name="twitter:card" content={headers.twitterCard} />
                <meta name="twitter:title" content={headers.twitterTitle} />
                <meta name="twitter:description" content={headers.twitterDescription} />
                <meta name="twitter:site" content={headers.twitterSite} />
                <meta name="twitter:creator" content={headers.twitterCreator} />
                <meta name="theme-color" content={headers.themeColor}></meta>
                <link rel="icon" type="image/png" href={headers.icon} />
                <link rel="apple-touch-icon" href={headers.appleTouchIcon} />
                <meta property="og:image" content={headers.ogImage} />
                <meta name="twitter:image" content={headers.twitterImage} />
                <link rel="canonical" href={headers.canonical} />
                <link rel="manifest" href={headers.manifest} />
                {/* <meta name="viewport" content="width=device-width" /> */}
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

                {this.props?.schema ?
                    <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{ __html: JSON.stringify(this.props?.schema) }}
                        key="page-jsonld"
                    /> :
                    <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
                        key="page-jsonld"
                    />}

                {this.props?.schema2 ?
                    <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{ __html: (JSON.stringify(this.props?.schema2)) }}
                        key="page-sub2-jsonld"
                    />
                    : null
                }

                {this.props?.schema3 ?
                    <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{ __html: (JSON.stringify(this.props?.schema3)) }}
                        key="page-sub3-jsonld"
                    />
                    : null
                }

                {this.props?.noindexTag ?
                    <meta name="robots" content="noindex" />
                    : null
                }
            </Head>
        )
    }

    private getThemeColor() {
        if (Config.portal.appId == 0) return "#1071b9";
        if (Config.portal.appId == 10) return "#f05b26";
        return "#1071b9";
    }
}
